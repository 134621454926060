import React, { useEffect } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { HeaderPlaceholder } from "../styles/ui.styles";
import { useCartStore } from "../lib/store";

const SuccessPage: React.FC = () => {
  // clear cart contents after successful purchase
  const { clearCart } = useCartStore();

  useEffect(() => {
    clearCart();
  }, []);

  return (
    <>
      <SEO title={"Payment Success"} description={"Payment Success"} />
      <HeaderPlaceholder />
      <Layout>
        <Wrapper>
          <h1>Thank you for your purchase!</h1>
          <Link to="/">Return to the home page</Link>
        </Wrapper>
      </Layout>
    </>
  );
};

export default SuccessPage;

const Wrapper = styled.div`
  height: 50vh;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 var(--gutter);
`;
